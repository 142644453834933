html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent; }

body {
  font-size: 10px;
  font-family: Arial, Helvetica, sans-serif; }

textarea, input, select {
  font-family: Helvetica, Arial, sans-serif;
  box-sizing: border-box; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

:focus {
  outline: 0; }

ins {
  text-decoration: none; }

del {
  text-decoration: line-through; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  cursor: pointer;
  text-decoration: none; }

html, body {
  height: 100%; }

body {
  overflow-x: auto;
  overflow-y: scroll; }

.wixSiteProperties {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; }

html.device-mobile-optimized {
  overflow-x: hidden; }

body.device-mobile-optimized {
  overflow-x: hidden;
  overflow-y: scroll; }
  body.device-mobile-optimized #SITE_CONTAINER {
    width: 320px;
    overflow-x: visible;
    margin: 0 auto;
    position: relative; }
  body.device-mobile-optimized > * {
    max-width: 100% !important; }
  body.device-mobile-optimized .SITE_ROOT {
    overflow-x: hidden;
    overflow-y: hidden; }
body.device-mobile-non-optimized .SITE_ROOT {
  overflow-x: hidden;
  overflow-y: auto; }
body.device-mobile-non-optimized.fullScreenMode {
  background-color: #5f6360; }
  body.device-mobile-non-optimized.fullScreenMode .SITE_ROOT,
  body.device-mobile-non-optimized.fullScreenMode #SITE_BACKGROUND,
  body.device-mobile-non-optimized.fullScreenMode #MOBILE_ACTIONS_MENU {
    visibility: hidden; }
body.fullScreenMode {
  overflow-x: hidden !important;
  overflow-y: hidden !important; }
  body.fullScreenMode.device-mobile-optimized {
    overflow-x: hidden !important;
    overflow-y: auto !important; }
    body.fullScreenMode.device-mobile-optimized .SITE_ROOT,
    body.fullScreenMode.device-mobile-optimized #SITE_STRUCTURE {
      overflow-x: hidden !important;
      overflow-y: hidden !important; }
    body.fullScreenMode.device-mobile-optimized #SITE_STRUCTURE,
    body.fullScreenMode.device-mobile-optimized #SITE_BACKGROUND {
      height: auto !important; }

.SITE_ROOT {
  min-height: 100%;
  position: relative;
  margin: 0 auto; }

body[contenteditable] {
  overflow-x: auto;
  overflow-y: auto; }

.bold {
  font-weight: bold; }

.italic {
  font-style: italic; }

.underline {
  text-decoration: underline; }

.lineThrough {
  text-decoration: line-through; }

.singleLineText {
  white-space: nowrap;
  text-overflow: ellipsis; }

.alignLeft {
  text-align: left; }

.alignCenter {
  text-align: center; }

.alignRight {
  text-align: right; }

.alignJustify {
  text-align: justify; }

ul.font_100, ol.font_100 {
  color: #080808;
  font-family: 'Arial, Helvetica, sans-serif', serif;
  font-size: 10px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin: 0;
  text-decoration: none;
  line-height: normal;
  letter-spacing: normal; }
  ul.font_100 li, ol.font_100 li {
    margin-bottom: 12px; }

letter {
  position: relative;
  display: inline-block; }

word {
  display: inline-block;
  white-space: nowrap; }

word.space,
letter.space {
  display: inline; }

.wixapps-less-spacers-align.ltr {
  text-align: left; }
.wixapps-less-spacers-align.center {
  text-align: center; }
.wixapps-less-spacers-align.rtl {
  text-align: right; }
.wixapps-less-spacers-align > div,
.wixapps-less-spacers-align > a {
  display: inline-block !important; }

.flex_display {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex; }

.flex_vbox {
  box-sizing: border-box;
  padding-top: 0.01em;
  padding-bottom: 0.01em; }

a.wixAppsLink img {
  cursor: pointer; }

.singleLine {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal; }

[data-z-counter] {
  z-index: 0; }

[data-z-counter="0"] {
  z-index: auto; }

.circle-preloader {
  animation: semi-rotate 1s 1ms linear infinite;
  height: 30px;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform-origin: 100% 50%;
  width: 15px; }
  .circle-preloader::before {
    content: '';
    top: 0;
    left: 0;
    right: -100%;
    bottom: 0;
    border: 3px solid currentColor;
    border-color: currentColor transparent transparent currentColor;
    border-radius: 50%;
    position: absolute;
    transform: rotate(-45deg);
    animation: inner-rotate 0.5s 1ms linear infinite alternate;
    color: #7fccf7; }
  .circle-preloader::after {
    content: '';
    top: 0;
    left: 0;
    right: -100%;
    bottom: 0;
    border: 3px solid currentColor;
    border-color: currentColor transparent transparent currentColor;
    border-radius: 50%;
    position: absolute;
    transform: rotate(-45deg);
    animation: inner-rotate 0.5s 1ms linear infinite alternate;
    color: #3899ec;
    opacity: 0; }
  .circle-preloader.white::before {
    color: #f0f0f0; }
  .circle-preloader.white::after {
    color: #dcdcdc; }

@keyframes inner-rotate {
  to {
    opacity: 1;
    transform: rotate(115deg); } }
@keyframes semi-rotate {
  from {
    animation-timing-function: ease-out;
    transform: rotate(180deg); }
  45% {
    transform: rotate(198deg); }
  55% {
    transform: rotate(234deg); }
  to {
    transform: rotate(540deg); } }
.hidden-comp-ghost-mode {
  opacity: 0.5; }
